<template>
  <div id="accounts">
    <b-dropdown right class="accounts">
      <template #button-content>
        <span class="trimmed-text">{{ $store.state.account.name }}</span>
        <span class="badge badge-warning badge-select" v-if="$store.state.account.type == 'trial'">{{$t('acc-settings.trial')}}</span>
      </template>
      <div b-dropdown-item v-for="account in accounts" :key="account.id">
        <a
          v-if="account.status == 'active'"
          class="dropdown-item"
          href="javascript:void(0)"
          @click.prevent="switchAccount(account.id)"
        >
          <span class="trimmed-text">{{ account.name }}</span>
          <span
            class="badge badge-warning badge-select"
            v-if="account.type == 'trial'"
          >
            {{$t('acc-settings.trial')}}
          </span>
        </a>
        <a
          v-else-if="account.status == 'suspended'"
          class="dropdown-item"
          href="javascript:void(0)"
        >
          {{ account.name }}
          <span class="badge badge-danger badge-select"> {{$t('generic-str.status.lbl-suspended')}} </span>
        </a>
      </div>
      <div b-dropdown-item>
        <router-link to="/accounts" class="dropdown-item"
          >{{$t('acc-settings.view-all')}}</router-link
        >
      </div>
      <b-dropdown-divider></b-dropdown-divider>
      <div b-dropdown-item>
        <router-link to="/accounts/create" class="dropdown-item">
          {{$t('acc-settings.create.new-2')}}
        </router-link>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import AccountService from '@/services/account.service';
import Dropdown from '@/directives/Dropdown';

export default {
  name: 'Toolbar',
  directives: {
    Dropdown,
  },
  data() {
    return {
      accounts: [],
      selectedAccount: '',
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    switchAccount(accountId) {
      const load = document.createElement('div');
      load.className = 'qt-block-ui';
      document.body.appendChild(load);

      this.$store
        .dispatch('account/select', accountId)
        .then(
          () => {
            if (this.$router.currentRoute.path !== '/') {
              this.$router.push('/');
            }
            this.$root.$emit('change.account', accountId);
            this.$root.$emit('checkClient');
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => {
          load.remove();
        });
    },
    fetch() {
      AccountService.getAccounts().then(
        (response) => {
          this.accounts = response;
          this.loaded = true;
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
#accounts {
  margin-top: .35em;
  float: right;
}

@media (max-width: 620px) {
  #accounts {
    display: none;
  }
}

#accounts .dropdown::v-deep {
  border-radius: 0;
}
#accounts .dropdown::v-deep > button {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  color: #617182 !important;
  padding: 8px !important;
}

#accounts .dropdown::v-deep .dropdown-menu {
  overflow: auto;
  overflow-x: hidden;
  max-height: calc(90vh - 62px);
  min-height: 156px;
}

.badge-warning {
  margin-right: 5px;
  margin-left: 5px;
  color: #000;
}

.trimmed-text {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  max-width: 170px;
  vertical-align: middle;
}

.dropdown-item .trimmed-text {
  width: 170px;
}
</style>
