<template>
  <nav id="top-menu">
    <!-- <button class="toggle-btn" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMainMenu" aria-controls="sidebarMainMenu" aria-expanded="true" aria-label="Toggle navigation">
      toggle
    </button> -->
    <button
      class="btn toggle-btn back-btn"
      v-if="backBtn"
      v-on:click="$router.go(-1)"
    >
      <i class="fas fa-chevron-left"></i>
    </button>
    <b-button v-b-toggle.sidebarMainMenu class="toggle-btn" v-else
      >toggle</b-button
    >

    <header>
      <template v-if="!whitelabelReady">
        <div class="placeholder-logo"></div>
      </template>
      <template v-else-if="whitelabelLogo">
        <img :src="whitelabelLogo" class="logo-v2" />
      </template>
      <template v-else>
        <img class="logo-v2" alt="yup chat" src="@/assets/logo-v2.svg" />
      </template>

      <div class="row">
        <!-- <div class="col-6">
          <b-dropdown right class="notifications">
            <template #button-content>
              <i class="fas fa-bell"></i>
            </template>
            <b-dropdown-form>
              <h4>{{$tc('generic-str.notification', 2)}}</h4>
            </b-dropdown-form>
          </b-dropdown>
        </div> -->

        <div class="col-12">
          <b-dropdown right class="profile">
            <template #button-content>
              <span class="dev-tag" :class="{ hide: isProduction }">dev</span>
              <i class="fas fa-user mr-2"></i>
              <!-- <i
                :class="`status-online status ${$live.status}`"
                v-if="account.is_agent"></i> -->
              <!-- <span class="badge live-break" v-if="account.is_agent">
                {{$live.statusTime | roundTime}}
              </span> -->
              <div
                v-if="account.is_agent"
                class="status-online status"
                :class="{
                  'bg-success': $live.status === 'AVAILABLE',
                  'bg-warning': $live.status === 'UNAVAILABLE',
                  'bg-danger': $live.status === 'OFFLINE',
                  'bg-info': $live.status === 'BREAK',
                }"
              ></div>
            </template>
            <li role="presation">
              <router-link class="dropdown-item" to="/user/settings">
                <span class="badge live-break" v-if="account.is_agent">
                  {{ $live.statusTime | roundTime }}
                </span>
                <i class="fas fa-user fa-fw"></i>
                <div class="trimmed-text">{{ name }}</div>
              </router-link>
              <router-link
                to="/billing"
                :class="{
                  badge: true,
                  'badge-balance': true,
                  'bg-primary': true,
                  'bg-danger': account.balance <= 0,
                  'bg-warning': account.balance > 0 && account.balance < 10,
                }"
              >
                {{ account.balance | currency }}
              </router-link>
            </li>
            <!-- <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item>
              <i class="fas fa-circle icon-online"></i> Online
            </b-dropdown-item>
            <b-dropdown-item>
              <i class="fas fa-circle icon-away"></i> Ausente
            </b-dropdown-item>
            <b-dropdown-item>
              <i class="fas fa-circle icon-invisible"></i> Invisivel
            </b-dropdown-item> -->
            <b-dropdown-divider></b-dropdown-divider>
            <!-- <b-dropdown-item>{{$live.status}}</b-dropdown-item> -->
            <template v-if="account.is_agent">
              <b-dropdown-item
                @click="$live.setStatus('AVAILABLE')"
                :active="$live.status === 'AVAILABLE'"
              >
                <!-- <i class="fas fa-check-circle text-success"></i> -->
                <span class="live-status bg-success"></span>
                Disponível
              </b-dropdown-item>
              <b-dropdown-item
                @click="$live.setStatus('UNAVAILABLE')"
                :active="$live.status === 'UNAVAILABLE'"
              >
                <span class="live-status bg-warning"></span>
                Indisponível
              </b-dropdown-item>
              <!-- <b-dropdown-item
                @click="$live.setStatus('SHORT_BREAK');"
                :active="$live.status === 'SHORT_BREAK'">
                <span class="live-status bg-info"></span>
                Pausa curta
              </b-dropdown-item> -->
              <b-dropdown-item
                @click="$live.setStatus('BREAK')"
                :active="$live.status === 'BREAK'"
              >
                <!-- <i class="fas fa-pause"></i> -->
                <span class="live-status bg-info"></span>
                Pausa
              </b-dropdown-item>
              <!-- <b-dropdown-item
                @click="$live.setStatus('LONG_BREAK');"
                :active="$live.status === 'LONG_BREAK'">
                <span class="live-status bg-info"></span>
                Pausa longa
              </b-dropdown-item> -->
              <b-dropdown-item
                @click="$live.setStatus('OFFLINE')"
                :active="$live.status === 'OFFLINE'"
              >
                <!-- <i class="fas fa-minus-circle text-danger"></i> -->
                <span class="live-status bg-danger"></span>
                Offline
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
            </template>
            <b-dropdown-item v-on:click="logout">
              <i class="fas fa-sign-out-alt"></i> Logout
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <account-select />
      <router-link
        type="button"
        to="/billing"
        :class="{
          btn: true,
          'btn-balance': true,
          'btn-primary': true,
          'btn-danger': account.balance <= 0,
          'btn-warning': account.balance > 0 && account.balance < 10,
        }"
      >
        {{ account.balance | currency }}
      </router-link>
    </header>
  </nav>
</template>

<script>
import AccountSelect from '@/components/menu/AccountSelect.vue';

export default {
  name: 'Toolbar',
  components: {
    AccountSelect,
  },
  props: {
    backBtn: Boolean,
  },
  filters: {
    roundTime: (value) => {
      if (!value) return '--:--';

      const hours = Math.floor(value / 60 / 60);
      const mins = Math.floor((value / 60) % 60);
      const secs = Math.floor(value % 60);
      return `${hours}:${mins}:${secs}`
        .replace(/\b(\d)\b/g, '0$1')
        .replace(/^00:/, '');
    },
  },
  computed: {
    whitelabelReady() {
      return !!(this.$store?.state?.admin?.whitelabel?.id);
    },
    whitelabelLogo() {
      return this.$store?.state?.admin?.whitelabel?.logo ?? null;
    },
    account() {
      return this.$store?.state?.account;
    },
    name() {
      return this.$store?.state?.auth?.user?.name;
    },
    email() {
      return this.$store?.state?.auth?.user?.email;
    },
    menu() {
      return this.$route.path.split('/')[1];
    },
    isProduction() {
      return process.env.NODE_ENV === 'production';
    },
  },
  // data() {
  //   return {
  //     name: this.$store.state.auth.user.name,
  //     email: this.$store.state.auth.user.email,
  //     menu: this.$route.path.split('/')[1],
  //   };
  // },
  mounted() {
    this.checkLive();
  },
  methods: {
    checkLive() {
      // this.hasNotification = this.$live.hasNotification;

      // // console.log('this.hasNotification', this.hasNotification);
      // setTimeout(() => this.checkLive(), 1000);

      // this.isLiveOnline = this.$live.online;

      // console.log('Sidebar.vue:checkLive');

      this.$live.setup(this);

      // this.$live.on('$live:ready', () => {
      //   console.log(this.isLiveOnline);
      // });

      // this.$live.on('online', () => {
      //   console.log('Toobar.vue:online');
      //   this.isLiveOnline = true;
      // });

      // this.$live.on('offline', () => {
      //   console.log('Toobar.vue:offline');
      //   this.isLiveOnline = false;
      // });
    },
    async logout() {
      console.log('LOGOUT', this.$live.ready);

      await this.$live.disconnect();

      this.$store.dispatch('auth/logout').then(async () => {
        this.$router.push('/login');
      });
    },
    getInitials(str) {
      const splitNames = str.trim().split(' ');
      if (splitNames.length > 1) {
        if (splitNames[1].length > 3) {
          return `${splitNames[0].charAt(0).toUpperCase()}${splitNames[1]
            .charAt(0)
            .toUpperCase()}`;
        }

        return `${splitNames[0].charAt(0).toUpperCase()}${splitNames[2]
          .charAt(0)
          .toUpperCase()}`;
      }
      return splitNames[0].charAt(0).toUpperCase();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/scss/_variables';

.trimmed-text {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  width: 200px;
  vertical-align: middle;
}

.initials {
  background-color: #d6dadd;
  color: #4d5a68;
  font-weight: 600;
  line-height: 2.5;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  display: inline-block;
}

#top-menu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  min-width: 320px;
  border-bottom: 1px solid #d6dadd;
  background: #fff;
  height: 62px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);

  .btn-balance {
    border: none;
    border-radius: 1em;
    padding: 0.2em 1em;
    margin: 1em 0.5em 0 0 !important;
    float: right;
    font-size: 0.8rem;
    height: auto !important;
    display: none;

    @media (min-width: 660px) {
      display: block;
    }
  }

  .badge-balance {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
    display: none;

    @media (max-width: 659px) {
      display: block;
      background: #fc0;
    }
  }
}

#top-menu header {
  padding: 4px 0;
}

#top-menu .toggle-btn {
  float: left;
  border: none !important;
  background: transparent center center no-repeat !important;
  color: transparent !important;
  border: none !important;
  border-right: 1px solid #d6dadd !important;
  width: 62px !important;
  height: 62px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

#top-menu .toggle-btn:not(.back-btn) {
  background-image: url(../../assets/btn-menu.svg) !important;
}

#top-menu .logo {
  background: url(../../assets/logo.svg) no-repeat;
  background-size: contain;
  width: 168px;
  height: 30px;
  float: left;
  margin-top: 0.7em;
  margin-left: 1.5em;
  position: relative;
}

#top-menu > header {
  & > .logo-v2, & > .placeholder-logo {
    width: 120px;
    height: 30px;
    float: left;
    margin-top: 0.7em;
    margin-left: 1.5em;
    position: relative;
  }
}

.dev-tag {
  position: absolute;
  top: -4px;
  right: -4px;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.1em 0.5em;
  border-radius: 0.3em;
  color: #fff;
  text-transform: uppercase;
  font-size: 7pt;
}
#top-menu .row {
  margin: 0;
  margin-top: 0.35em;
  margin-right: 0.25em;
  max-width: 92px;
  float: right;

  & > div {
    padding: 0;
    width: 46px;
  }
}

#top-menu .dropdown::v-deep {
  border-radius: 100%;
  position: relative !important;
  height: 2.5em !important;
  width: 2.5em !important;
  color: #0f0f0f !important;
  border: none !important;
}

#top-menu .dropdown::v-deep > button {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  color: #d6dadd !important;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  padding: 0;
}

#top-menu::v-deep .dropdown.show > button {
  color: #752de6 !important;
}

#top-menu::v-deep .dropdown > button:hover {
  background: #f3eff4 !important;
}

#top-menu::v-deep .dropdown > button i {
  margin: 0 !important;
  color: inherit !important;
}

#top-menu::v-deep .dropdown > button::after {
  display: none;
}

#top-menu::v-deep .profile > button {
  background: #9ca7b0 !important;
  color: #0c0c0c !important;
}

#top-menu::v-deep {
  .live-status {
    border-radius: 12px;
    vertical-align: -2px;
    display: inline-block;
    height: 12px;
    width: 12px;
    margin-right: 5px;
  }

  .live-break {
    min-width: 42px;
    text-align: center;
    font-size: 8pt;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    color: #fff;
    padding: 0 4px;
    position: absolute;
    // bottom: 0;
    // right: 0;
    // top: auto;
    top: -5px;
    right: 3px;
  }
}

#top-menu::v-deep .profile > button .status {
  border: 0.2em solid #fff;
  background: #ff0000;
  width: 1em;
  height: 1em;
  position: absolute;
  bottom: -0.3em;
  left: -0.3em;
  content: '';
  border-radius: 100%;

  &.online {
    background: #00cc00;
  }
}

#top-menu::v-deep .dropdown-menu {
  top: auto !important;
  left: auto !important;
  transform: none !important;
}

#top-menu::v-deep .profile .fa-circle {
  font-size: 9pt;
}

#top-menu::v-deep .profile .dropdown-item > i {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

#top-menu::v-deep .notifications > button {
  background: transparent;
  color: #d6dadd;
  border: none;
}

/*#top-menu .notifications button:hover {
  color: #752de6 !important;
}*/

#top-menu::v-deep .notifications form {
  padding: 0;
}

#top-menu::v-deep .notifications form h4 {
  font-weight: bold;
  font-size: 110%;
  padding: 0.9em;
  background: #fff;
  margin: 0;
  border-bottom: 1px solid #d6dadd;
}

#top-menu::v-deep .notifications form aside {
  border: none !important;
  border-bottom: 1px solid #fcfcfc;
  max-height: 320px;
  overflow: auto;
}

@media (max-width: 420px) {
  #top-menu .logo {
    margin-top: 0.85em;
    width: 110px;
  }

  #top-menu .notification-content {
    position: fixed !important;
    left: 1% !important;
    transform: translate(0, 50px) !important;
    height: auto !important;
    width: 98% !important;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3) !important;
  }

  #top-menu .notification-content aside {
    max-height: 70vh !important;
  }
}
</style>
