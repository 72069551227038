var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{attrs:{"id":"top-menu"}},[(_vm.backBtn)?_c('button',{staticClass:"btn toggle-btn back-btn",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"fas fa-chevron-left"})]):_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebarMainMenu",modifiers:{"sidebarMainMenu":true}}],staticClass:"toggle-btn"},[_vm._v("toggle")]),_c('header',[(!_vm.whitelabelReady)?[_c('div',{staticClass:"placeholder-logo"})]:(_vm.whitelabelLogo)?[_c('img',{staticClass:"logo-v2",attrs:{"src":_vm.whitelabelLogo}})]:[_c('img',{staticClass:"logo-v2",attrs:{"alt":"yup chat","src":require("@/assets/logo-v2.svg")}})],_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-dropdown',{staticClass:"profile",attrs:{"right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"dev-tag",class:{ hide: _vm.isProduction }},[_vm._v("dev")]),_c('i',{staticClass:"fas fa-user mr-2"}),(_vm.account.is_agent)?_c('div',{staticClass:"status-online status",class:{
                'bg-success': _vm.$live.status === 'AVAILABLE',
                'bg-warning': _vm.$live.status === 'UNAVAILABLE',
                'bg-danger': _vm.$live.status === 'OFFLINE',
                'bg-info': _vm.$live.status === 'BREAK',
              }}):_vm._e()]},proxy:true}])},[_c('li',{attrs:{"role":"presation"}},[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/user/settings"}},[(_vm.account.is_agent)?_c('span',{staticClass:"badge live-break"},[_vm._v(" "+_vm._s(_vm._f("roundTime")(_vm.$live.statusTime))+" ")]):_vm._e(),_c('i',{staticClass:"fas fa-user fa-fw"}),_c('div',{staticClass:"trimmed-text"},[_vm._v(_vm._s(_vm.name))])]),_c('router-link',{class:{
                badge: true,
                'badge-balance': true,
                'bg-primary': true,
                'bg-danger': _vm.account.balance <= 0,
                'bg-warning': _vm.account.balance > 0 && _vm.account.balance < 10,
              },attrs:{"to":"/billing"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.account.balance))+" ")])],1),_c('b-dropdown-divider'),(_vm.account.is_agent)?[_c('b-dropdown-item',{attrs:{"active":_vm.$live.status === 'AVAILABLE'},on:{"click":function($event){return _vm.$live.setStatus('AVAILABLE')}}},[_c('span',{staticClass:"live-status bg-success"}),_vm._v(" Disponível ")]),_c('b-dropdown-item',{attrs:{"active":_vm.$live.status === 'UNAVAILABLE'},on:{"click":function($event){return _vm.$live.setStatus('UNAVAILABLE')}}},[_c('span',{staticClass:"live-status bg-warning"}),_vm._v(" Indisponível ")]),_c('b-dropdown-item',{attrs:{"active":_vm.$live.status === 'BREAK'},on:{"click":function($event){return _vm.$live.setStatus('BREAK')}}},[_c('span',{staticClass:"live-status bg-info"}),_vm._v(" Pausa ")]),_c('b-dropdown-item',{attrs:{"active":_vm.$live.status === 'OFFLINE'},on:{"click":function($event){return _vm.$live.setStatus('OFFLINE')}}},[_c('span',{staticClass:"live-status bg-danger"}),_vm._v(" Offline ")]),_c('b-dropdown-divider')]:_vm._e(),_c('b-dropdown-item',{on:{"click":_vm.logout}},[_c('i',{staticClass:"fas fa-sign-out-alt"}),_vm._v(" Logout ")])],2)],1)]),_c('account-select'),_c('router-link',{class:{
        btn: true,
        'btn-balance': true,
        'btn-primary': true,
        'btn-danger': _vm.account.balance <= 0,
        'btn-warning': _vm.account.balance > 0 && _vm.account.balance < 10,
      },attrs:{"type":"button","to":"/billing"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.account.balance))+" ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }